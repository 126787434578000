/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300;400;500;600;700&display=swap");
@import url("https://use.typekit.net/oiv2fob.css");

body {
  background: #ffffff;
  font-family: Centra No2, sans-serif;
}

.App {
  width: 100%;
  height: 100%;

  /* font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Black.woff2") format("woff2"),
    url("/fonts/CentraNo2-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Book.woff2") format("woff2"),
    url("/fonts/CentraNo2-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-BoldItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-BlackItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Bold.woff2") format("woff2"),
    url("/fonts/CentraNo2-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-BookItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2 Hairline";
  src: url("/fonts/CentraNo2-Hairline.woff2") format("woff2"),
    url("/fonts/CentraNo2-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Extrabold.woff2") format("woff2"),
    url("/fonts/CentraNo2-Extrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-ExtraboldItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-ExtraboldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Medium.woff2") format("woff2"),
    url("/fonts/CentraNo2-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-LightItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Light.woff2") format("woff2"),
    url("/fonts/CentraNo2-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2 Hairline";
  src: url("/fonts/CentraNo2-HairlineItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-ThinItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-Thin.woff2") format("woff2"),
    url("/fonts/CentraNo2-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra No2";
  src: url("/fonts/CentraNo2-MediumItalic.woff2") format("woff2"),
    url("/fonts/CentraNo2-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

.dangerous-innerHTML > ul {
  list-style: decimal;
  margin-left: 15px;
}

.dangerous-innerHTML > ul > li {
  margin-bottom: 10px;
}
