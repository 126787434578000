@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ql-editor ul,
  .ql-editor ol {
    list-style: revert;
    padding-left: 20px;
  }

  .ql-editor h2 {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: black;
  }

  .ql-editor h1 {
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: black;
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: white;
  border: #007AFF;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #007AFF;
}